<template>
  <div>
    <h2 v-html="$t('search_label_bundle_card')"></h2>
    <!-- Label Bundle -->
    <!-- :item-title="this.$getLangKey()" -->
    <v-select
      id="search_label_bundle_card_label_type"
      v-model="searchCardStore.labelTypeId"
      :label="$t('search_label_bundle_card_label_type')"
      :items="labelTypes"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!--    Tierart-->
    <v-select
      id="search_label_bundle_card_animal_type"
      v-model="searchCardStore.animalTypeId"
      :label="$t('search_label_bundle_card_animal_type')"
      :items="animalTypes"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!--    Status-->
    <v-select
      id="search_label_bundle_card_label_status"
      v-model="searchCardStore.complied"
      :label="$t('search_label_bundle_card_label_status')"
      :items="activeOptions"
      :item-title="this.$getLangKey()"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
    <!-- Stichtag -->
    <DbmDatePicker v-model="searchCardStore.dueDate" :dateLabel="$t('search_label_bundle_card_due_date')" :required="true" @keyup.enter="search" />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useSearchLabelStore } from '@/store/SearchLabelStore'
import { useLabelTypesStore } from '@/store/enums/LabelTypesStore'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchLabelData',
  extends: baseSearchVue,
  data() {
    return {
      activeOptions: [
        {
          nameDe: this.$t('search_contract_status_active'),
          nameFr: this.$t('search_contract_status_active'),
          nameIt: this.$t('search_contract_status_active'),
          value: true
        },
        {
          nameDe: this.$t('search_contract_status_inactive'),
          nameFr: this.$t('search_contract_status_inactive'),
          nameIt: this.$t('search_contract_status_inactive'),
          value: false
        }
      ]
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  computed: {
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    labelTypes() {
      return useLabelTypesStore().LabelTypes
    }
  },
  methods: {},
  beforeMount() {
    useAnimalTypesStore().fetchAnimalTypes()
    useLabelTypesStore().fetchLabelTypes()
    this.setSearchCardStore(useSearchLabelStore(), true)
  }
})
</script>
