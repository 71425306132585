import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'
import { format } from 'date-fns'

export const useSearchLabelStore = defineStore('searchLabelStore', {
  state: () => ({
    labelTypeId: null as number | null,
    animalTypeId: 1 as number | null,
    complied: null as string | null,
    dueDate: format(new Date(), 'yyyy-MM-dd') as string | null,
    items: [
      { key: 'labelTypeId', operation: ':' },
      { key: 'animalTypeId', operation: ':', defaultValue: 1 },
      { key: 'complied', operation: ':' },
      {
        key: 'dueDate',
        operation: ':',
        itemType: 'string',
        defaultValue: format(new Date(), 'yyyy-MM-dd')
      }
    ] as TermItemDefinition[]
  })
})
